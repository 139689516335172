import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Image from "next/image";

import { ButtonElement } from "../buttonElement";
import { CHECK, Icon } from "../icon";
import { CLOSE_WITH_TEXT } from "../icon/IconSet";
import { Input } from "../input/Input";

import {
    BackgroundImageContainer,
    BottomDescription,
    CloseButton,
    Description,
    ModalContent,
    StyledModal,
    SuccessMessage
} from "./NewsletterModal.styled";

type FormValues = {
    email: string;
};

type Props = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
};

export function NewsletterModal({ isOpen, setIsOpen }: Props): JSX.Element {
    const [success, setSuccess] = useState(false);

    const methods = useForm({
        defaultValues: { email: "" }
    });

    const {
        formState: { errors },
        watch,
        register,
        handleSubmit
    } = methods;

    const email = watch("email");

    const hasErrors = Object.keys(errors).length > 0;

    const addProfileToReloadify = async () => {
        await fetch(`/api/reloadify/add-profile?email=${email}`);
    };

    const onSubmit: SubmitHandler<FormValues> = async () => {
        addProfileToReloadify();
        setSuccess(true);
    };

    useEffect(() => {
        const newsletterModalCookie = Cookies.get("newsLetterModalSeen");

        const delayTimeout = setTimeout(() => {
            if (!newsletterModalCookie) {
                setIsOpen(true);
                Cookies.set("newsLetterModalSeen", "true", { expires: 365 });
            }
        }, 7000);

        return () => {
            clearTimeout(delayTimeout);
        };
    }, [setIsOpen]);

    function onClose() {
        setIsOpen(false);
        Cookies.set("newsLetterModalSeen", "true", { expires: 365 });
    }

    return (
        <StyledModal
            showModal={isOpen}
            isOpen={isOpen}
            contentLabel="Aanmelden voor de nieuwsbrief"
            onClose={() => onClose()}
            title="Er als de kippen bij zijn?"
            contentClassName="newsletter-modal"
            maxWidth={660}
            minWidth={284}
        >
            <BackgroundImageContainer>
                <Image
                    src="/images/nieuwsbrief-kip.png"
                    alt="nieuwbrief kip"
                    role="presentation"
                    layout="fill"
                    objectFit="contain"
                    unoptimized
                />
            </BackgroundImageContainer>
            <CloseButton onClick={() => onClose()}>
                <Icon icon={CLOSE_WITH_TEXT} size={32} />
            </CloseButton>
            <ModalContent>
                <Description>
                    Mr. Fillet acties, nieuws en recepten rechtstreeks in jouw
                    mailbox ontvangen? Sign up!
                </Description>

                {success && (
                    <SuccessMessage>
                        <Icon icon={CHECK} stroke="var(--color-beige-60)" />
                        Je bent succesvol ingeschreven voor onze nieuwsbrief.
                    </SuccessMessage>
                )}
                {!success && (
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Input
                                {...register("email", {
                                    pattern: {
                                        message:
                                            "Vul een geldig e-mailadres in.",
                                        value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
                                    },
                                    required: "E-mailadres is verplicht"
                                })}
                                inputType="email"
                                label="E-mailadres"
                                name="email"
                                placeholder="bv. hennie@gmail.com"
                                isValid={email?.length > 0 && !errors?.email}
                                errorMessage={errors?.email?.message}
                            />
                            <ButtonElement
                                type="submit"
                                reversedOrder
                                buttonType="secondary"
                                disabled={hasErrors}
                            >
                                Inschrijven
                            </ButtonElement>
                        </form>
                    </FormProvider>
                )}
                <BottomDescription>
                    Afmelden kan altijd via de link onderaan onze mailings.
                </BottomDescription>
            </ModalContent>
        </StyledModal>
    );
}
