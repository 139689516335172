import { useState } from "react";
import Image from "next/image";

import { Address } from "components/footer/address/Address";
import {
    BackroundContainer,
    Container,
    InnerGrid,
    InnerWrapper,
    Wrapper
} from "components/footer/Footer.styled";
import { Copyright } from "components/footer/copyright/Copyright";
import { Info } from "components/footer/info/Info";
import { Navigation } from "components/footer/navigation/Navigation";
import { NewsletterModal } from "components/shared/newsletterModal/NewsletterModal";

import { useMediaQueryContext } from "helpers/hooks";

export default function Footer(): JSX.Element {
    const { isMobileView } = useMediaQueryContext();
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <Container>
                <NewsletterModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                />
                <Wrapper>
                    <Info />
                    <InnerWrapper>
                        {!isMobileView && (
                            <BackroundContainer>
                                <Image
                                    src="/images/now-were-tokkin.svg"
                                    layout="fill"
                                    objectFit="cover"
                                    alt="now we're tokkin"
                                    role="presentation"
                                />
                            </BackroundContainer>
                        )}
                        <InnerGrid>
                            <Navigation setIsModalOpen={setIsModalOpen} />
                            <Address />
                        </InnerGrid>
                    </InnerWrapper>
                </Wrapper>
            </Container>
            <Copyright />
        </>
    );
}
