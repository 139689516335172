// import dynamic from "next/dynamic";
import Image from "next/image";
import PatternBeige from "public/images/pattern-beige-01.png";
import PatternRed from "public/images/pattern-red.png";

import {
    BackgroundContainer,
    Container,
    LogoWrapper,
    Wrapper
} from "components/footer/info/Info.styled";
import { Link } from "components/shared/link/Link";
import { LogoVertical } from "components/shared/svg/LogoVertical";
import { Socials } from "components/footer/info/socials/Socials";

import { useMediaQueryContext } from "helpers/hooks";

export function Info(): JSX.Element | null {
    const { isTabletViewOrLarger } = useMediaQueryContext();

    return (
        <Container>
            <BackgroundContainer>
                {isTabletViewOrLarger ? (
                    <Image
                        src={PatternBeige}
                        alt="pattern-beige"
                        objectFit="contain"
                        layout="fill"
                    />
                ) : (
                    <Image
                        src={PatternRed}
                        alt="pattern-red"
                        objectFit="contain"
                        quality={90}
                    />
                )}
            </BackgroundContainer>
            <Wrapper>
                <LogoWrapper>
                    <Link href="/" noUnderline>
                        <LogoVertical
                            fill="var(--color-beige-50)"
                            width={128}
                            height={69}
                        />
                    </Link>
                </LogoWrapper>
                <Socials />
            </Wrapper>
        </Container>
    );
}
