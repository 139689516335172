import { FacebookIcon } from "components/shared/svg/FacebookIcon";
import { InstagramIcon } from "components/shared/svg/InstagramIcon";
import {
    Item,
    List
} from "components/footer/info/socials/socialLinks/SocialLinks.styled";

export default function SocialLinks(): JSX.Element {
    return (
        <List>
            <Item>
                <a
                    href="https://www.facebook.com/mrfillet.shop"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FacebookIcon />
                </a>
            </Item>
            <Item>
                <a
                    href="https://www.instagram.com/mr_fillet/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <InstagramIcon />
                </a>
            </Item>
        </List>
    );
}
