import {
    Container,
    Handle
} from "components/footer/info/socials/Socials.styled";
import SocialLinks from "components/footer/info/socials/socialLinks/SocialLinks";

export function Socials(): JSX.Element {
    return (
        <Container>
            <Handle>
                volg{" "}
                <a
                    href="https://www.facebook.com/mrfillet.shop"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <strong>@mr_fillet</strong>
                </a>
            </Handle>
            <SocialLinks />
        </Container>
    );
}

export default Socials;
