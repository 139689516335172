import styled from "styled-components";

import { Modal } from "../modal";

import { media } from "constants/media";

export const StyledModal = styled(Modal)``;

export const BackgroundImageContainer = styled.div`
    height: 338px;
    position: absolute;
    right: -49px;
    top: -82px;
    width: 100%;

    > span {
        transform: rotate(333deg);
    }

    ${media.mdUp} {
        bottom: 0;
        height: 554px;
        top: -30px;
        width: 375px;

        > span {
            transform: rotate(0);
        }
    }

    ${media.lgUp} {
        height: 564px;
        width: 375px;
    }
`;

export const ModalContent = styled.div`
    button {
        display: inline-grid;
        width: 100%;
    }

    ${media.mdUp} {
        display: inline-block;
        margin-top: 8px;
        min-width: 313px;
        text-align: left;

        button {
            width: auto;
        }
    }
`;

export const BottomDescription = styled.div`
    font-size: 14px;
    line-height: 24px;
    margin-top: 16px;

    ${media.mdUp} {
        font-size: 16px;
        line-height: 32px;
        margin-top: 24px;
    }
`;

export const Description = styled.div`
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 16px;

    ${media.mdUp} {
        font-size: 18px;
        line-height: 32px;
    }
`;

export const SuccessMessage = styled.div`
    background-color: var(--color-green-50);
    color: var(--color-beige-40);
    font-size: 14px;
    line-height: 24px;
    padding: 8px 8px 8px 42px;
    position: relative;
    width: 100%;

    svg {
        left: 8px;
        position: absolute;
        top: 8px;
    }

    ${media.mdUp} {
        font-size: 16px;
        line-height: 32px;
        padding: 8px 8px 8px 50px;

        svg {
            left: 16px;
            top: 19px;
        }
    }
`;

export const CloseButton = styled.div`
    background: var(--color-white);
    cursor: pointer;
    padding: 8px 8px 3px;
    position: absolute;
    right: 0;
    top: 0;

    ${media.mdUp} {
        padding: 8px 8px 0;
    }
`;
