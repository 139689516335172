import styled from "styled-components";

import { Link } from "components/shared/link/Link";
import { LinkUnderline } from "components/shared/svg/LinkUnderline";
import { Underline } from "components/shared/link/Link.styled";

import { media } from "constants/media";

export const List = styled.ul`
    font-size: 16px;
    line-height: 24px;
    list-style: none;
    position: relative;
    text-align: center;
    z-index: 3;

    ${media.lgUp} {
        font-size: 18px;
        line-height: 32px;
    }
`;

export const NewsLetterItem = styled.p`
    color: var(--color-brown-50);
    position: relative;
    text-align: left;
    white-space: nowrap;

    &:hover,
    &:active {
        color: var(--color-orange-50);

        svg {
            margin-left: 0;
            transition: all var(--default-transition-duration) linear;
        }

        > ${Underline} {
            left: 0;
            opacity: 1;
            transition: all var(--default-transition-duration) linear;
            width: calc(100% - 24px);
        }
    }
`;

export const ExternalLink = styled.a`
    color: var(--color-brown-50);
    position: relative;
    text-align: left;
    white-space: nowrap;

    &:hover,
    &:active {
        color: var(--color-orange-50);

        svg {
            margin-left: 0;
            transition: all var(--default-transition-duration) linear;
        }

        > ${Underline} {
            left: 0;
            opacity: 1;
            transition: all var(--default-transition-duration) linear;
            width: calc(100% - 24px);
        }
    }
`;

export const Item = styled.li`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    text-align: left;

    ${media.mdUp} {
        justify-content: flex-start;
    }

    a {
        color: var(--color-brown-50);
        text-decoration: none;
    }
`;

export const FooterLink = styled(Link)`
    font-size: inherit;
    line-height: inherit;
`;

export const Nav = styled.nav`
    display: grid;
    gap: 16px;
    margin: 0;
    position: relative;
    width: 100%;
    z-index: 2;

    ${media.mdUp} {
        gap: 24px;
        grid-template-columns: repeat(2, 1fr);
    }

    ${media.lgUp} {
        gap: 48px;
    }
`;

export const Label = styled.span`
    font-size: inherit;
    line-height: inherit;
    position: relative;
    z-index: 4;
`;

export const Line = styled(LinkUnderline)`
    margin: 0 auto;
    max-width: 265px;
`;
