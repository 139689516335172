import styled from "styled-components";

import { media } from "constants/media";

export const Container = styled.div`
    background-color: var(--color-brown-50);
    color: var(--color-beige-50);
    font-size: 14px;
    line-height: 24px;

    position: relative;
    width: 100%;
    z-index: 2;

    ${media.lgUp} {
        font-size: 16px;
        line-height: 32px;
    }
`;

export const Wrapper = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    padding: 24px;
    text-align: center;
    width: 100%;

    ${media.mdUp} {
        display: flex;
        flex-direction: row-reverse;
        padding: 8px 24px;
    }

    ${media.lgUp} {
        padding: 8px 0;
    }
`;

export const Item = styled.li`
    margin-bottom: 8px;

    ${media.mdUp} {
        margin-bottom: 0;

        :nth-of-type(1) {
            margin-right: 8px;
        }
    }

    ${media.lgUp} {
        :nth-of-type(1) {
            margin-right: 40px;
        }
    }

    a {
        font-size: 14px;
        line-height: 24px;

        ${media.lgUp} {
            font-size: 16px;
            line-height: 32px;
        }
    }
`;

export const Links = styled.ul`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;

    ${media.mdUp} {
        flex-direction: row;
        margin-left: auto;
    }
`;

export const Word = styled.span`
    font-size: 14px;

    line-height: 24px;
    margin-right: 16px;

    ${media.lgUp} {
        font-size: 16px;
        line-height: 32px;
    }
`;

export const Text = styled.p`
    align-items: center;
    display: flex;
    justify-content: center;

    ${media.mdUp} {
        flex-direction: row-reverse;
    }
`;
