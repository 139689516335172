import styled from "styled-components";

import { media } from "constants/media";

export const Container = styled.footer`
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;
`;

export const Wrapper = styled.div`
    display: grid;
    gap: 16px;
    max-width: 1200px;
    position: relative;
    width: 100%;
    z-index: 0;

    ${media.mdUp} {
        gap: 24px;
        grid-template-columns: repeat(9, 1fr);

        > *:nth-child(1) {
            grid-column: span 4;
            grid-row: 1 / 2;
        }

        > *:nth-child(2) {
            grid-column: span 5;
        }
    }

    ${media.lgUp} {
        gap: 48px;
        grid-template-columns: repeat(12, 1fr);

        > *:nth-child(1) {
            grid-column: span 4;
            grid-row: 1;
        }

        > *:nth-child(2) {
            grid-column: span 8;
            grid-row: 1;
        }
    }
`;

export const InnerWrapper = styled.div`
    background-color: var(--color-beige-40);
    padding-top: 24px;
    position: relative;
    width: 100%;
    z-index: 3;

    ${media.mdUp} {
        padding-top: 55px;
    }

    ${media.lgUp} {
        padding-top: 196px;
    }
`;

export const InnerGrid = styled.div`
    display: grid;
    gap: 16px;

    ${media.mdUp} {
        gap: 24px;
        grid-template-columns: repeat(5, 1fr);

        > *:nth-child(1) {
            grid-column: span 5;
            grid-row: 1 / 3;
        }

        > *:nth-child(2) {
            grid-column: span 5;
        }
    }

    ${media.lgUp} {
        gap: 48px;
        grid-template-columns: repeat(8, 1fr);

        > *:nth-child(1) {
            grid-column: span 4;
        }

        > *:nth-child(2) {
            grid-column: span 4;
        }
    }
`;

export const BackroundContainer = styled.div`
    height: 460px;
    opacity: 0.5;
    pointer-events: none;
    position: absolute;
    right: -30%;
    top: 30%;
    transform: rotate(125.68deg);
    width: 460px;
    z-index: 1;

    ${media.lgUp} {
        height: 1013px;
        right: -40%;
        top: -10%;
        width: 1013px;
    }

    ${media.xlUp} {
        right: -27%;
        top: -9%;
    }
`;
