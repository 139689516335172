import {
    BorderLine,
    Container,
    Item,
    List,
    Title,
    Wrapper
} from "components/footer/address/Address.styled";
import { Link } from "components";
import TrustBox from "components/footer/trustbox/TrustBox";

import { useMediaQueryContext } from "helpers/hooks";

export function Address(): JSX.Element {
    const { isDesktopView } = useMediaQueryContext();
    return (
        <Container>
            <Wrapper>
                {isDesktopView && <BorderLine />}
                <Title>Mr. Fillet B.V.</Title>
                <List>
                    <Item>Röntgenweg 8</Item>
                    <Item>3752 LJ Bunschoten-Spakenburg</Item>
                    <Item>
                        <Link
                            href="mailto:yourmainman@mr-fillet.shop"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            yourmainman@mr-fillet.shop
                        </Link>
                    </Item>
                </List>
                <TrustBox />
            </Wrapper>
        </Container>
    );
}
