import { useEffect, useRef } from "react";

function TrustBox(): JSX.Element {
    const ref = useRef(null);

    useEffect(() => {
        window?.Trustpilot?.loadFromElement(ref.current, true);
    }, []);

    return (
        <div
            ref={ref}
            className="trustpilot-widget"
            data-locale="nl-NL"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="64809bfcdc5cb52419b44813"
            data-style-height="110px"
            data-style-width="60%"
            data-theme="light"
        >
            <a
                href="https://nl.trustpilot.com/review/mr-fillet.shop"
                target="_blank"
                rel="noopener noreferrer"
            >
                Trustpilot
            </a>
        </div>
    );
}

export default TrustBox;
