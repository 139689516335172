import styled from "styled-components";

import { LogoMobile } from "components/shared/svg/LogoMobile";

import { media } from "constants/media";

export const Container = styled.div`
    max-width: 100%;
    overflow: hidden;
    padding: 24px;
    position: relative;
    width: 100%;
    z-index: 1;

    ${media.mdUp} {
        background-color: var(--color-red-50);
        display: flex;
        flex: 1 1 100%;
        max-width: 424px;
        padding: 65px 40px 69px;
    }
`;

export const BackgroundContainer = styled.div`
    height: 525px;
    left: -252px;
    position: absolute;
    top: -124px;
    width: 933px;
    z-index: 1;

    ${media.lgUp} {
        height: 768px;
        left: -179px;
        top: -188px;
        width: 1366px;
    }
`;
export const Wrapper = styled.div`
    align-items: center;
    background-color: var(--color-red-50);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 64px 0;
    position: relative;
    width: 100%;
    z-index: 2;

    ${media.mdUp} {
        height: 325px;
        padding: 48px 0 0;
    }
`;

export const LogoWrapper = styled.div`
    margin-bottom: 28px;

    &:hover {
        svg {
            margin: 0;
        }
    }

    ${media.mdUp} {
        margin-bottom: 52px;
    }
`;

export const Logo = styled(LogoMobile)`
    ${media.mdUp} {
        height: 149px;
        width: 160px;
    }
`;
