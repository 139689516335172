import styled from "styled-components";

export const List = styled.ul`
    display: flex;
    gap: 10px;
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const Item = styled.li`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
`;
