import { useSession } from "next-auth/react";

import {
    ExternalLink,
    FooterLink,
    Item,
    Label,
    Line,
    List,
    Nav,
    NewsLetterItem
} from "components/footer/navigation/Navigation.styled";
import { Underline } from "components/shared/link/Link.styled";

import { useMediaQueryContext } from "helpers/hooks";

export function Navigation({
    setIsModalOpen
}: {
    setIsModalOpen: (value: boolean) => void;
}): JSX.Element {
    const { data: session } = useSession();
    const accessToken = session?.accessToken;
    const { isMobileView } = useMediaQueryContext();
    return (
        <Nav>
            <List>
                <Item>
                    <FooterLink href="/kip-bestellen" variant="orange">
                        Kip bestellen
                    </FooterLink>
                </Item>
                {/* TODO: Enable these links when pages exisist */}
                {/* <Item>
                    <Link href="/hoe-werkt-het" variant="orange">
                        <Label>Hoe werkt het?</Label>
                    </Link>
                </Item>
                <Item>
                    <Link href="/recepten" variant="orange">
                        <Label>Recepten</Label>
                    </Link>
                </Item>
                   */}
                <Item>
                    <FooterLink
                        href="https://www.google.com/maps/place/Mr.+Fillet+B.V./@52.2287285,5.3577848,16z/data=!4m7!3m6!1s0x47c6418617a2c42b:0x7670ec8a6d14d522!8m2!3d52.2287519!4d5.3591837!9m1!1b1"
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="orange"
                    >
                        <Label>Ervaringen</Label>
                    </FooterLink>
                </Item>
                <Item>
                    <FooterLink href="/faq" variant="orange">
                        <Label>FAQ</Label>
                    </FooterLink>
                </Item>
            </List>
            {isMobileView && (
                <Line
                    fill="var(--color-beige-50)"
                    width={265}
                    height={2}
                    preserveAspectRatio="none"
                />
            )}
            <List>
                <Item>
                    <FooterLink href="/inloggen" variant="orange">
                        <Label>Registreren</Label>
                    </FooterLink>
                </Item>
                <Item onClick={() => setIsModalOpen(true)}>
                    <NewsLetterItem>
                        <Label>Aanmelden Nieuwsbrief</Label>
                        <Underline
                            preserveAspectRatio="none"
                            fill="currentColor"
                            $isSimple
                        />
                    </NewsLetterItem>
                </Item>
                <Item>
                    <ExternalLink
                        href="https://www.werkenbijmrfillet.nl/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Werken bij
                        <Underline
                            preserveAspectRatio="none"
                            fill="currentColor"
                            $isSimple
                        />
                    </ExternalLink>
                </Item>
                {accessToken && (
                    <Item>
                        <FooterLink
                            href="/mijn-account/bestellingen"
                            variant="orange"
                        >
                            <Label>Mijn bestellingen</Label>
                        </FooterLink>
                    </Item>
                )}
            </List>
        </Nav>
    );
}
