import styled from "styled-components";

import { LinkUnderline } from "components/shared/svg/LinkUnderline";

import { media } from "constants/media";

export const Container = styled.div`
    background-color: var(--color-beige-55);
    padding: 24px;
    position: relative;
    width: 100%;
    z-index: 3;

    a {
        text-decoration: none;
        transition: all 250ms;
    }

    ${media.mdUp} {
        background-color: transparent;
        padding: 0;
    }

    ${media.lgUp} {
        padding-left: 24px;
    }
`;

export const Wrapper = styled.div`
    margin: auto;
    width: 272px;

    ${media.mdUp} {
        margin: unset;
        width: unset;
    }
`;

export const Title = styled.h2`
    color: var(--color-brown-50);
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 10px;

    ${media.mdUp} {
        font-size: 24px;
        line-height: 40px;
    }
`;

export const Item = styled.li`
    font-size: 16px;
    line-height: 24px;

    ${media.lgUp} {
        font-size: 18px;
        line-height: 32px;
    }
`;

export const List = styled.ul`
    list-style: none;

    ${Item} {
        :nth-of-type(2),
        :nth-of-type(3) {
            margin-bottom: 8px;
        }
    }
`;

export const BorderLine = styled(LinkUnderline)`
    height: 194px;
    left: -97px;
    position: absolute;

    transform: rotate(-90deg);
    width: 194px;

    > path {
        fill: var(--color-beige-50);
    }
`;

export const Underline = styled(LinkUnderline)`
    bottom: 0;
    left: 0;
    position: absolute;
    width: 165px;
`;
