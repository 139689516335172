import styled from "styled-components";

import { media } from "constants/media";

export const Container = styled.div`
    align-items: center;
    color: var(--color-beige-50);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: 2;
`;

export const Handle = styled.div`
    font-family: var(--font-primary);
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 28px;

    ${media.lgUp} {
        margin-bottom: 12px;
    }

    a {
        color: var(--color-beige-50);
        font-size: 18px;
        line-height: 32px;

        &:hover {
            color: var(--color-beige-60);
        }
    }
`;
