import {
    Container,
    Item,
    Links,
    Text,
    Word,
    Wrapper
} from "components/footer/copyright/Copyright.styled";
import { Link } from "components/shared/link/Link";

export function Copyright(): JSX.Element {
    const links = [
        {
            link: {
                label: "Algemene voorwaarden",
                url: "/algemene-voorwaarden"
            }
        },
        { link: { label: "Privacy & cookies", url: "/privacy-policy" } }
    ];

    const currentYear = new Date().getFullYear();

    return (
        <Container>
            <Wrapper>
                <Links>
                    {links?.map(item => (
                        <Item key={`Copyright-link-${item.link.url}`}>
                            <Link href={item?.link?.url} variant="beige">
                                {item?.link?.label}
                            </Link>
                        </Item>
                    ))}
                </Links>
                <Text>
                    <Word>Mr. Fillet</Word>
                    <Word>&copy; {currentYear}</Word>
                </Text>
            </Wrapper>
        </Container>
    );
}
